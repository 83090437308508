export default {
    zh:{
        ttOa:{
            queryParam:{
                customerAmountId:'客户',
                customerAmountId_placeholder:'请选择客户',
                flag:'是否提交',
                flag_placeholder:'请选择是否提交',
            },
            columns:{
                F_CUSTOMER_AMOUNT_ID:'客户',
                F_FLAG:'是否提交',
                F_AMOUNT:'偿还金额',
            },
            form:{
                customerAmountId:'客户余额ID',
                customerAmountId_placeholder:'请输入客户余额ID',
            },
            TtOaDetail:{
                F_AMOUNT:'本次付款金额',
                F_AMOUNT_PLACEHOLDER:'请输入本次付款金额',
            },
        }
    },
    en:{
        ttOa:{
            queryParam:{
                customerAmountId:'客户',
                customerAmountId_placeholder:'请选择客户',
                flag:'是否提交',
                flag_placeholder:'请选择是否提交',
            },
            columns:{
                F_CUSTOMER_AMOUNT_ID:'客户',
                F_FLAG:'是否提交',
                F_AMOUNT:'偿还金额',
            },
            form:{
                customerAmountId:'客户余额ID',
                customerAmountId_placeholder:'请输入客户余额ID',
            },
            TtOaDetail:{
                F_AMOUNT:'本次付款金额',
                F_AMOUNT_PLACEHOLDER:'请输入本次付款金额',
            },
        }
    }
}