import TtOaCard from './TtOaCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import TtOaListUtil,{ITtOaListDataObj} from './ttOaListUtil';
import language from './ttOaLanguage'
export default defineComponent ({
    name: 'TtOaList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:ITtOaListDataObj=reactive<ITtOaListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                cardFrom:'TtOaList',
                queryParam: {},
                modelComp: TtOaCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/ttOa/pageData')
            },
            otherParams:{
                customerData:[]
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtOaListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
                if(params && 'bz'==params.comboId){
                    return [{value:0,label:'人民币'},{value:1,label:'美元'}]
                }
                if(params && 'flag'==params.comboId){
                    return [{value:0,label:'否'},{value:1,label:'是'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{

            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});